import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeSolutions = () => {
    return (
        <section data-scrollax-parent="true" id="KPIs">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>highlights </div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>KPIs</h3>
                    <h2>AI <span> HIGHLIGHTS</span></h2>
          
                </div>
                <div className="process-wrap fl-wrap">
                    <ul>
                        <li>
                            <div className="time-line-icon">
                                <i className="fab pragicts-bespoke-icon"></i>
                            </div>
                            <h4>Bespoke</h4>
                            <div className="process-details">
                                {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                                <p>Tailored AI solutions as per area of focus and specialization.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">01.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-web-icon"></i>
                            </div>
                            <h4>Deliver Across Web/Mobile Platforms</h4>
                            <div className="process-details">
                                {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                                <p>Empower your stakeholders (customers, staff, etc.) through AI-enabled mobile apps for a step change in productivity.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">02.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-digital-landscape-icon"></i>
                            </div>
                            <h4>Integrate Across The Entire Digital Landscape.</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>PragICTS specializes in software development, ITOps, CloudOps, IoT, Sensors, and AI.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">03.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-driven-ai-icon"></i>
                            </div>
                            <h4>Business Driven AI</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>Engagements of AI are ground up and driven from the business end for effective solution conceptualization.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">04.</span>
                        </li>
                      
                    </ul>
                </div>
            </div>
           
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeSolutions

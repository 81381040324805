import React from 'react'
import NumbersBgImage from '../images/bg/bg-stats.jpg'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'



import hr from '../images/services/new/hr.jpg'
import agriculture from '../images/services/new/pragicts-ai-agriculture.jpg'
import making from '../images/services/new/pragicts-ai-decision-making.jpg'

import ecommerce from '../images/services/new/pragicts-ai-ecommerce.jpg'

import finance from '../images/services/new/pragicts-ai-finance.jpg'

import healthcare from '../images/services/new/pragicts-ai-healthcare.jpg'
import hotels from '../images/services/new/pragicts-ai-hotels.jpg'
import it from '../images/services/new/pragicts-ai-it.jpg'

import legal from '../images/services/new/pragicts-ai-legal.jpg'


import logistics from '../images/services/new/pragicts-ai-logistics.jpg'
import production from '../images/services/new/pragicts-ai-manufacturing.jpg'
import planning from '../images/services/new/pragicts-ai-planning.jpg'
import quality from '../images/services/new/pragicts-ai-quality.jpg'
import sale from '../images/services/new/pragicts-ai-sales-and-marketing.jpg'
import travel from '../images/services/new/pragicts-ai-travel.jpg'


const HomeStats = () => {

    return (
        <section className="dark-bg no-padding" id="verticals">
             <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>Verticals </div>
            <div >
                <div className="section-title fl-wrap">
                    <h3>Our Verticals</h3>
                    <h2>AI ENABLEMENT  <span> VERTICALS</span></h2> 
          
                </div>
        <div className="fs-carousel-wrap fl-wrap full-height slider-carousel-wrap">
            <div className="fs-carousel-2 fl-wrap full-height cur_carousel-slider-container">
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>HR </h3>
                       
                    </div>
                    <div className="carousle-item-number"><span>01.</span></div>
                    <div className="bg"  data-bg={hr}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>FINANCE</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>02.</span></div>
                    <div className="bg"  data-bg={finance}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>SALE AND MARKETING</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>03.</span></div>
                    <div className="bg"  data-bg={sale}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>E-COMMERCE</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>04.</span></div>
                    <div className="bg"  data-bg={ecommerce}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>IT</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>05.</span></div>
                    <div className="bg"  data-bg={it}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>AGRICULTURE</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>06.</span></div>
                    <div className="bg"  data-bg={agriculture}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>PLANNING</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>07.</span></div>
                    <div className="bg"  data-bg={planning}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>QUALITY AND BEST PRACTICES</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>08.</span></div>
                    <div className="bg"  data-bg={quality}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>LOGISTICS</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>09.</span></div>
                    <div className="bg"  data-bg={logistics}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>LEGAL</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>10.</span></div>
                    <div className="bg"  data-bg={legal}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>DECISION MAKING</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>11.</span></div>
                    <div className="bg"  data-bg={making}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>PRODUCTION</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>12.</span></div>
                    <div className="bg"  data-bg={production}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>HOTELS</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>13.</span></div>
                    <div className="bg"  data-bg={hotels}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>TRAVEL</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>14.</span></div>
                    <div className="bg"  data-bg={travel}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3>HEALTH CARE</h3>
                      
                    </div>
                    <div className="carousle-item-number"><span>15.</span></div>
                    <div className="bg"  data-bg={healthcare}></div>
                    <div className="overlay"></div>
                </div>
            </div>
            <div className="sp-cont sarr-contr sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
            <div className="sp-cont sarr-contr sp-cont-next"><i className="fal fa-arrow-right"></i></div>
            <div className="slider-nav-counter-2"></div>
        </div>
        </div>
    </section>

    )
}

export default HomeStats

import React from 'react'
import HeroImage1 from '../images/hero-images/pragicts-ai-banner-insights.jpg'
import HeroImage2 from '../images/hero-images/pragicts-ai-banner-decision-making.jpg'
import HeroImage3 from '../images/hero-images/pragicts-ai-banner-at-you-finger-tips.jpg'
import HeroImage4 from '../images/hero-images/pragicts-ai-banner-automation.jpg'
import HeroImage5 from '../images/hero-images/pragicts-ai-banner-new-possibilities.jpg'
import HeroImage6 from '../images/hero-images/pragicts-ai-banner-private-ai.jpg'


const slides = [
    {
        id: 1,
        image: HeroImage1,
        mainText: 'Insights On Your Enterprise  <br />Data Like Never Before <br /> <span>AI By PragICTS</span>',
       
       
    },
    {
        id: 2,
        image: HeroImage2,
        mainText: 'Aggregate Data For Effective  <br />Decision-Making <br /> <span>AI By PragICTS</span>', 

    },
    {
        id: 3,
        image: HeroImage3,
        mainText: 'Insights & Online Conveniences  <br />At Your Finger Tips <br /> <span>AI By PragICTS</span>',

    },
    {
        id: 4,
        image: HeroImage4,
        mainText: 'Track, Action &  <br />Automate <br /> <span>AI By PragICTS</span>',

    },
  
    {
        id: 5,
        image: HeroImage5,
        mainText: 'Engineer New Horizons,  <br />Possibilities and Capabilities <br /> <span>AI By PragICTS</span>',
    
    },
    {
        id: 6,
        image: HeroImage6,
        mainText: 'Have You Own Private  <br />AI Implementation <br /> <span>AI By PragICTS</span>',

    },
  

]

const HeroSlides = () => {

    return (
        <div className="hero-wrap" id="sec1" data-scrollax-parent="true">
            <div className="slider-carousel-wrap full-height fullscreen-slider-wrap">
                <div className="fullscreen-slider full-height cur_carousel-slider-container fl-wrap" data-slick='{"autoplay": true, "autoplaySpeed": 4000 , "pauseOnHover": false}'>
                {slides.map(slide => (
                    <div key={slide.image} className="fullscreen-slider-item full-height fl-wrap">
                        <div className="bg par-elem hero-bg"  data-bg={slide.image}></div>
                        <div className="overlay"></div>
                        <div className="half-hero-wrap">
                            <h1 dangerouslySetInnerHTML={{ __html: slide.mainText }}></h1>
                        
                            <div className="clearfix"></div>
                            {/* <a href={slide.action.link} className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">Let's Start</a> */}
                        </div>
                    </div>
                ))}
                </div>
                <div className="sp-cont   sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
                <div className="sp-cont   sp-cont-next"><i className="fal fa-arrow-right"></i></div>
                <div className="fullscreenslider-counter"></div>
            </div>
            <div className="hero-decor-numb">
                <span>6.9149106  </span><span>79.9105932 </span>
                <a href="https://g.page/PragICTS?share" target="_blank" className="hero-decor-numb-tooltip">Based In Colombo, Sri Lanka</a>
            </div>
        </div>
    )
}

export default HeroSlides
